import * as firebase from 'firebase';

class FB {
    answerCollection: any;
    db: any;

    constructor() {
        const config = {
            apiKey: "AIzaSyBD2t_M6LcLECqcYnHclKIp3-zHVAnPeDI",
            authDomain: "deartech.firebaseapp.com",
            databaseURL: "https://deartech.firebaseio.com",
            projectId: "deartech",
            storageBucket: "deartech.appspot.com",
            messagingSenderId: "5325740953"
        };

        firebase.initializeApp(config);

        this.db = firebase.firestore();
        this.answerCollection = this.db.collection("answers");
    }

    async createNewAnswer(answer: any) {
        return await this.answerCollection.add({
            identity: answer.identity,
            mood: answer.mood,
            reason: answer.reason,
            sortBy: this.getRandomNumber(),
        });
    }

    async updateAnswer(id: any, answer: any) {
        return this.answerCollection.doc(id).update(answer);
    }

    async getAnswer(id: any) {
        return await this.answerCollection.doc(id).get();
    }

    async getRandomAnswer(exclude: string[]): Promise<any> {
        let results;
        let randomNumber = this.getRandomNumber();
        let direction;

        if (this.getRandomNumber() < 500) {
            results = await this.answerCollection.where("sortBy", "<", randomNumber).orderBy('sortBy').limit(1).get();
        } else {
            results = await this.answerCollection.where("sortBy", ">", randomNumber).orderBy('sortBy', 'asc').limit(1).get();
        }

        if(!results.docs.length) {
            return this.getRandomAnswer(exclude);
        }

        results = results.docs[0];

        return {
            id: results.id,
            ...results.data(),
        };
    }


    async getAllAnswers() {
        return this.answerCollection.limit(4).get();
    }

    getRandomNumber() {
        return Math.floor(Math.random() * 1000) + 1;
    }
}

export default new FB();
import React, {Component} from 'react';
import Slider from 'rc-slider';
import {withRouter} from 'react-router';
import FB from '../service/firebase';

class Score extends Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            score: 0,
            loading: false,
        }
    }

    handleChange(value: any) {
        this.setState({
            score: value
        });
    }

    async updateAnswer(e: any) {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true,
        });

        const {id} = this.props.match.params;

        await FB.updateAnswer(id, {score: this.state.score});

        this.props.history.push(`/done/${id}`);
    };

    render() {
        const button = this.state.loading ? null : (<button className="submitButton"
                                                            type="submit">
            NEXT
        </button>);

        return (
            <div className="form">
                <form onSubmit={(e) => this.updateAnswer(e)}>
                    <div className="sliderDiv">
                        <div className="rangeDiv">
                            <p className="dearTechTitle">Dear Tech, I would rate our relationship
                                a
                            </p>

                            <Slider className="slider" min={0} max={10} defaultValue={4}
                                    aria-valuenow={this.state.score} value={this.state.score}
                                    onChange={(value) => this.handleChange(value)}
                            />

                            <div className="val" style={({left: this.state.score + '0%'})}>
                                <p className="value">
                                    <span className="valueSpan">{this.state.score}</span>/10
                                </p>
                            </div>
                        </div>

                        {button}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(Score);
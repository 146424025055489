import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';


import {withRouter} from 'react-router';

import FB from '../service/firebase';

class Gallery extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            answers: [],
        };
    }

    async componentDidMount() {
        await this.startFetching(0);
        await this.startFetching(1);
        await this.startFetching(2);
        await this.startFetching(3);
        await this.startFetching(4);
        await this.startFetching(5);
    }

    render() {
        const answers = this.state.answers.map((answer: any, i: number) => (
            <div key={answer.id + ' ' + i}>
                <div className={'miniQuote miniQuote-fixed miniQuote-' + i}>
                    <p className="value">{answer.score}/10</p>
                    <p>"Dear Tech, I'm a <span>{answer.identity}</span>. Lately I feel <span>{answer.mood}</span> when I think of
                        you, because <span>{answer.reason}</span>"</p>
                </div>
            </div>
        ));

        return (
            <div className="allQuotes">
                <ReactCSSTransitionGroup
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                    transitionName="example">
                    {answers}
                </ReactCSSTransitionGroup>
            </div>
        );
    }

    async startFetching(index: number) {
        await this.fetchNewAnswer(index);

        setTimeout(() => this.startFetching(index), this.getRandomNumber(5000, 30000));
    }

    async fetchNewAnswer(index: number): Promise<any> {
        const ids = this.state.answers.filter((a: any) => !!a).map((a: any) => a.id);

        const answer = await FB.getRandomAnswer(ids);

        if(ids.indexOf(answer.id) > -1) {
            return this.fetchNewAnswer(index);
        }

        this.setState((prevState: any) => {
            const answers = [
                ...prevState.answers,
            ];

            answers[index] = answer;

            return {answers};
        });
    }

    getRandomNumber(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}

export default withRouter(Gallery);
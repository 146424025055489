import React, {Component} from 'react';
import {withRouter} from 'react-router';
import FB from '../service/firebase';

class Done extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            answer: {},
        };

        FB.getAnswer(this.props.match.params.id)
            .then((answer) => {
                this.setState({
                    answer: answer.data()
                });
            })
    }

    done() {
        this.props.history.push(`/info`);
    };

    render() {
        return (
            <div>
                <div key={this.state.answer.id} className="galleryQuote">
                    <p>"Dear Tech, I'm a <span>{this.state.answer.identity}</span>. Lately I
                        feel <span>{this.state.answer.mood}</span> when I think of you,
                        because <span>{this.state.answer.reason}</span>"</p>
                    <p className="value">
                        <span className="valueSpan"> {this.state.answer.score}</span> /10
                    </p>
                </div>

                <button className="submitButton"
                        onClick={() => this.done()}>
                    AGAIN
                </button>
            </div>
        );
    }
}

export default withRouter(Done);
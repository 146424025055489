import * as React from "react";

const Logo = (props: React.SVGProps<SVGSVGElement>) => (
        <svg className="logo" width="15rem" height="7rem">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="range" transform="translate(-449.000000, -70.000000)">
                    <g id="Group" transform="translate(449.028305, 70.000000)">
                        <path d="M15.7402497,26.4009402 L15.7402497,18.187747 L0.207240148,18.187747 L0.207240148,36.0179387 L4.03901152,36.0179387 C3.76655816,38.1888678 2.48982905,39.8752977 0.207240148,41.0756755 L0.207240148,47.7375395 C3.37530247,47.0371897 6.25348708,45.7343217 8.84337803,43.8320412 C11.3968363,41.9282078 13.1677831,39.6252837 14.1562185,36.9186103 C15.2111833,34.1482687 15.7386657,30.641861 15.7402497,26.4009402" id="Fill-1" fill="#191919"></path>
                        <polygon id="Stroke-3" fill="#191919" fillRule="nonzero" points="4.69421568 12.798004 5.68434189e-14 12.798004 5.68434189e-14 0 47.463057 0 47.463057 46.622031 17.3162023 46.622031 17.3162023 41.9278153 42.7688413 41.9278153 42.7688413 4.69421568 4.69421568 4.69421568"></polygon>
                        <path d="M23.4948742,20.5639671 L50.3442024,20.5639671" id="Stroke-5" stroke="#191919" strokeWidth="4.69421568"></path>
                        <g id="Group-2" transform="translate(56.705872, 3.299986)">
                            <path d="M13.3224294,14.0264897 C14.4007534,13.0302266 14.9417106,11.582293 14.9417106,9.68386496 C14.9417106,7.78308447 14.4007534,6.32103611 13.3224294,5.29771988 C12.2429087,4.27440365 10.5877233,3.76156931 8.35447973,3.76156931 L5.90581072,3.76156931 L5.90581072,15.5202961 L8.69197957,15.5202961 C10.7002233,15.5202961 12.2429087,15.0227527 13.3224294,14.0264897 M16.5454332,2.53241361 C18.422028,4.21912105 19.3603254,6.56569102 19.3603254,9.57212353 C19.3603254,12.578556 18.4447673,14.9580603 16.6160448,16.7094602 C14.7861255,18.4620362 11.9951694,19.3383243 8.24197979,19.3383243 L1.51472244,19.3383243 L1.51472244,0 L8.46578287,0 C11.9760205,0 14.6688383,0.844529945 16.5454332,2.53241361" id="Fill-7" fill="#191919"></path>
                            <path d="M32.195492,8.35449484 C31.6150402,7.87577104 30.9053331,7.63582103 30.0711579,7.63582103 C29.2345892,7.63582103 28.4710257,7.88518085 27.7768771,8.38272425 C27.0827285,8.88026766 26.6794043,9.5542449 26.5669043,10.4023035 L33.2091883,10.4023035 C33.1158373,9.51660568 32.7771407,8.83439487 32.195492,8.35449484 L32.195492,8.35449484 Z M36.2502773,17.1797151 C34.5412355,18.7676196 32.5377791,19.5592194 30.2399079,19.5592194 C27.9408398,19.5592194 26.0403088,18.8640701 24.5395117,17.4702429 C23.0387146,16.0775918 22.288316,14.2109219 22.288316,11.8678806 C22.288316,9.52601549 23.0518795,7.66287422 24.5825968,6.27963297 C26.1109205,4.89756795 27.9181005,4.20594732 30.0005463,4.20594732 C32.0829921,4.20594732 33.8518742,4.82346574 35.3071926,6.05850257 C36.7601174,7.29471562 37.4877767,8.99083286 37.4877767,11.1492067 L37.4877767,13.3628632 L26.4532076,13.3628632 C26.5848565,14.1732826 27.0169041,14.8390263 27.7481538,15.3542131 C28.4806002,15.8717523 29.3063977,16.1281695 30.2255462,16.1281695 C31.7083912,16.1281695 32.9279385,15.6400359 33.8853848,14.6625925 L36.2502773,17.1797151 Z" id="Fill-9" fill="#191919"></path>
                            <path d="M49.5364011,13.6670352 L49.5364011,12.9471851 L46.8902588,12.9471851 C45.2015628,12.9471851 44.3566164,13.4635481 44.3566164,14.4962742 C44.3566164,15.0314568 44.5576801,15.4419595 44.9622012,15.7277823 C45.3655255,16.0136051 45.9423869,16.1559284 46.6927854,16.1559284 C47.443184,16.1559284 48.1050188,15.9312693 48.677093,15.4784225 C49.250364,15.0267519 49.5364011,14.4233482 49.5364011,13.6670352 L49.5364011,13.6670352 Z M53.786266,19.3376185 L49.8164541,19.3376185 L49.8164541,17.5403459 C48.7285557,18.8859479 47.3821468,19.5599252 45.7772274,19.5599252 C44.1747016,19.5599252 42.8270958,19.1023734 41.7380006,18.1896224 C40.6501022,17.2768714 40.1055546,16.0594779 40.1055546,14.5374421 C40.1055546,13.0165824 40.6692512,11.8779961 41.7942506,11.1216831 C42.9216437,10.36537 44.4595419,9.98662542 46.4115356,9.98662542 L49.5639277,9.98662542 L49.5639277,9.90428963 C49.5639277,8.31756136 48.7106036,7.52478534 47.0027587,7.52478534 C46.2703122,7.52478534 45.4959775,7.66710863 44.6797545,7.95410766 C43.8647283,8.23993047 43.175367,8.59515059 42.6116704,9.01859179 L40.7255011,6.33562131 C42.7145959,4.91591707 44.9849405,4.20547683 47.5377318,4.20547683 C49.3760288,4.20547683 50.876826,4.65714745 52.0413201,5.56048867 C53.2034206,6.46500612 53.786266,7.89412016 53.786266,9.84900703 L53.786266,19.3376185 Z" id="Fill-11" fill="#191919"></path>
                            <g id="Group-15" transform="translate(57.719336, 4.081958)">

                                <g id="Clip-14"></g>
                                <path d="M8.28370605,4.02435311 C7.02586092,4.02435311 6.08756351,4.46190902 5.46761701,5.33819705 C4.84886731,6.21448509 4.53889406,7.37189103 4.53889406,8.80923865 L4.53889406,15.2561309 L0.317752504,15.2561309 L0.317752504,0.373349005 L4.53889406,0.373349005 L4.53889406,2.33646945 C5.08344166,1.72836084 5.76442537,1.2108216 6.58064838,0.787380399 C7.39687138,0.363939201 8.22625927,0.141632572 9.07120567,0.123989189 L9.09992906,4.02435311 L8.28370605,4.02435311 Z" id="Fill-13" fill="#191919" mask="url(#mask-2)"></path>
                            </g>
                            <g id="Group-18" transform="translate(0.073088, 22.216302)">

                                <g id="Clip-17"></g>
                                <path d="M6.49982395,8.29250775 L6.49982395,15.3792667 C6.49982395,15.9873753 6.66019621,16.4625704 6.97854712,16.8048521 C7.29809483,17.1483099 7.67628614,17.3200389 8.11671145,17.3200389 C8.95328019,17.3200389 9.66179049,16.9471754 10.2398487,16.2026246 L11.9153798,19.2902167 C10.518705,20.5452493 9.04782802,21.1721775 7.50274899,21.1721775 C5.95647315,21.1721775 4.6495589,20.6722817 3.58200623,19.67249 C2.51565037,18.6726982 1.98307084,17.3106291 1.98307084,15.5851062 L1.98307084,8.29250775 L0.0969015483,8.29250775 L0.0969015483,5.11669877 L1.98307084,5.11669877 L1.98307084,0.382390927 L6.49982395,0.382390927 L6.49982395,5.11669877 L10.3894497,5.11669877 L10.3894497,8.29250775 L6.49982395,8.29250775 Z" id="Fill-16" fill="#191919" mask="url(#mask-4)"></path>
                            </g>
                            <g id="Group-21" transform="translate(13.437443, 26.921205)">

                                <g id="Clip-20"></g>
                                <path d="M10.9975877,4.55822688 C10.378838,4.04774499 9.62604582,3.79368027 8.73801434,3.79368027 C7.85117967,3.79368027 7.03735028,4.05833102 6.3001166,4.58645629 C5.56168611,5.11693401 5.13322887,5.83207915 5.01354808,6.73424414 L12.0747148,6.73424414 C11.974183,5.7932637 11.6163374,5.06870877 10.9975877,4.55822688 L10.9975877,4.55822688 Z M15.3060962,13.9386256 C13.4905386,15.6241569 11.3614173,16.4675106 8.91873234,16.4675106 C6.47485056,16.4675106 4.45463878,15.7276647 2.85929381,14.2467967 C1.26275204,12.767105 0.465677964,10.7828125 0.465677964,8.292743 C0.465677964,5.80384973 1.27831054,3.82308591 2.90357571,2.35280397 C4.53003768,0.882522031 6.44971759,0.147381061 8.66381225,0.147381061 C10.8779069,0.147381061 12.7580922,0.804891144 14.304368,2.11755886 C15.849447,3.4314028 16.622585,5.23455657 16.622585,7.52702017 L16.622585,9.87947127 L4.89386729,9.87947127 C5.03389381,10.743997 5.49227125,11.4485562 6.2701964,11.9978535 C7.04812155,12.5471508 7.92657857,12.8200351 8.90317384,12.8200351 C10.4781731,12.8200351 11.7755129,12.3013197 12.7927996,11.2627125 L15.3060962,13.9386256 Z" id="Fill-19" fill="#191919" mask="url(#mask-6)"></path>
                            </g>
                            <g id="Group-24" transform="translate(32.586369, 26.921205)">

                                <g id="Clip-23"></g>
                                <path d="M8.8435728,12.5563254 C10.3395827,12.5563254 11.6859916,11.8211844 12.8827995,10.3509025 L15.5456972,13.2914663 C13.4704322,15.4086723 11.2264174,16.4672753 8.8136526,16.4672753 C6.39969102,16.4672753 4.36033031,15.7180197 2.69437368,14.2171559 C1.02961386,12.7186445 0.195438734,10.7578765 0.195438734,8.3360281 C0.195438734,5.91535592 1.03918832,3.94635434 2.72429388,2.42667093 C4.40939944,0.906987522 6.40926548,0.147145816 8.7226952,0.147145816 C9.88000846,0.147145816 11.0528802,0.382390927 12.2389169,0.852881147 C13.4249535,1.32337137 14.4673732,2.01969689 15.3649792,2.9406815 L13.0324005,5.94005665 C12.5129859,5.33194804 11.8798745,4.86263405 11.1318696,4.52858599 C10.3838646,4.19571416 9.64064688,4.02869013 8.9034132,4.02869013 C7.7257542,4.02869013 6.7144515,4.40625854 5.86591468,5.16139534 C5.01857467,5.91535592 4.59370786,6.96454911 4.59370786,8.30779869 C4.59370786,9.64987204 5.01857467,10.6943603 5.86591468,11.4389111 C6.7144515,12.1846381 7.70660527,12.5563254 8.8435728,12.5563254" id="Fill-22" fill="#191919" mask="url(#mask-8)"></path>
                            </g>
                            <path d="M56.0300415,34.6255996 L56.0300415,43.1532348 L51.5420118,43.1532348 L51.5420118,21.3342508 L56.0300415,21.3342508 L56.0300415,29.0973395 C57.3872217,27.7446801 58.9071678,27.0683504 60.5934701,27.0683504 C62.2785757,27.0683504 63.6991867,27.6564632 64.8565,28.8326887 C66.0138132,30.0089143 66.5918715,31.5768229 66.5918715,33.5375909 L66.5918715,43.1532348 L62.1038417,43.1532348 L62.1038417,34.507977 C62.1038417,32.077895 61.1954645,30.8616778 59.3823005,30.8616778 C58.4846946,30.8616778 57.7007854,31.1804349 57.0329666,31.8167729 C56.3639509,32.4542872 56.0300415,33.3905627 56.0300415,34.6255996" id="Fill-25" fill="#191919"></path>
                        </g>
                    </g >
                </g >
            </g >
        </svg>    
);

export default Logo;

import React, {Component} from 'react';
import FB from '../service/firebase';
import {withRouter} from 'react-router';

class Info extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            identity: "",
            mood: "",
            reason: "",
            loading: false,
        }
    }

    updateInput(event: any) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async addAnswer(e: any) {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true,
        });

        const docRef = await FB.createNewAnswer({
            identity: this.state.identity,
            mood: this.state.mood,
            reason: this.state.reason,
        });

        this.props.history.push(`/score/${docRef.id}`);
    };

    render() {
        const button = this.state.loading ? null : (<button className="submitButton" disabled={!this.state.identity || !this.state.mood || !this.state.reason}
                                                            type="submit">
            NEXT
        </button>);

        return (
            <div className="form">
                <form onSubmit={(e) => this.addAnswer(e)}>
                    <div className="formDiv">
                        <div className="subForm">
                            <p>Dear Tech, I'm a </p>
                            <input
                                type="text"
                                name="identity"
                                onChange={(event) => this.updateInput(event)}
                                value={this.state.identity}
                            />
                            <p>.</p>
                        </div>
                        <div className="subForm">
                            <p>Lately I feel</p>
                            <input
                                type="text"
                                name="mood"
                                onChange={(event) => this.updateInput(event)}
                                value={this.state.mood}
                            />
                            <p> when I think of you, </p>
                        </div>

                        <div className="subForm">
                            <p>because </p>
                            <input
                                type="text"
                                name="reason"
                                onChange={(event) => this.updateInput(event)}
                                value={this.state.reason}
                            />
                            <p>.</p>
                        </div>

                        {button}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(Info);
import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';
import {RangeSlider, Slider} from "reactrangeslider";

import './App.css';
import './css/style.css';

import Info from './pages/Info';
import Score from './pages/Score';
import Done from './pages/Done';
import Gallery from './pages/Gallery';

import Logo from "../src/icons/Logo";

class App extends Component {
    fullScreen() {
        if ((document as any).fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    }

    render() {
        return (
            <main>
                <div onClick={() => this.fullScreen()}>
                    <Logo/>
                </div>

                <Router>
                    <div>
                        <Route path='/info' exact component={Info}/>
                        <Route path='/score/:id' component={Score}/>
                        <Route path='/done/:id' component={Done}/>
                        <Route path='/gallery' component={Gallery}/>
                    </div>
                </Router>
            </main>
        );
    }
}

export default App;
